@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
body{ 
    background-color: #636b78;
}
html, body {
    height: 100%;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, img, ins, kbd, q, s, samp, small, strike, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5;
	color:#4A4A4A;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*****   TYPOGRAPHY   *****/
h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
	margin-bottom: 10px;
}
h1 {
	font-size: 80px;
	position: relative;
	margin-bottom: 0px;
}
h2 {
	font-size: 44px;
	position: relative;
}
h3 {
	font-size: 30px;
}
h4 {
	font-size: 22px;
}
h5 {
	font-size: 20px;
}
h6 {
	font-size: 18px;
}
p {
	margin-bottom: 10px;
}
@font-face {
  font-family: 'bpg';
  src: url('assets/fonts/bpg/BPGMrgvlovani2010.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/bpg/BPGMrgvlovani2010.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/bpg/BPGMrgvlovani2010.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/bpg/BPGMrgvlovani2010.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/bpg/BPGMrgvlovani2010.svg#svgFontName') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'bpgc';
  src: url('assets/fonts/bpgc/BPGMrgvlovaniCaps2010.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/bpgc/BPGMrgvlovaniCaps2010.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/bpgc/BPGMrgvlovaniCaps2010.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/bpgc/BPGMrgvlovaniCaps2010.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/bpgc/BPGMrgvlovaniCaps2010.svg#svgFontName') format('svg'); /* Legacy iOS */
}


/*****   SHORTCODES   *****/
body {
	background:#e0fdfe;
	font-size:14px;
	font-family:"bpgc";
}
#main_container {
	width:100%;
	height:auto;
	min-height: -moz-calc(100vh - 160px);
	min-height: -webkit-calc(100vh - 160px);
	min-height: calc(100vh - 160px);
	overflow: hidden;
}
button {
	outline: none !important;
}


/*****   LINKS   *****/
a {
	text-decoration:none !important;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	outline: none !important;
}
a:link, a:visited {
	color:#0d527b;
}
a:hover {
	color:#0f6a9e;
}
a:active  {
	color:#0d527b;
}
a img {
	border:none;
}


::selection{ background: #0d527b !important; color:#fff; }
::-moz-selection{ background: #0d527b !important; color:#fff; }


.footer {
	border-top: 1px solid #b5d7d8;
	margin-top: 20px;
	padding: 0 10px;
	color: #0d527b;
}
.footer table {
	width: 100%;
}
.footer table br {
	display: none;
}
@media screen and (max-width: 760px) {
	.footer table br {
		display: block;
	}
}
.footer table .rate {
	padding-right: 50px;
}
.footer table td:last-child {
	text-align: right;
}
.footer table td:last-child span {
	font-size: 24px;
}


.mat-autocomplete-panel {
	background: #0c4a6e;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
}
.mat-autocomplete-panel .mat-option {
	color: #FFF;
	font-family: 'bpg';
	font-size: 14px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
}
.mat-autocomplete-panel .mat-option:hover {
	background: #0d527b;
}

.users_list_wrapper .mat-card {
	padding: 0;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
	background: none;
}
.users_list_wrapper .emp-card.white {
	padding: 5px;
	-webkit-border-radius: 40px;
	-moz-border-radius: 40px;
	border-radius: 40px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
	cursor: pointer;
}
.users_list_wrapper .emp-card.white:hover {
	background: #d1f0f1;
}
.users_list_wrapper .emp-card.white .shortdate,
.users_list_wrapper .emp-card.white .month {
	display: none;
}
.users_list_wrapper .emp-card.white img {
	width: 46px;
	height: 46px;
	border: 1px solid #b5d7d8;
	-webkit-border-radius: 40px;
	-moz-border-radius: 40px;
	border-radius: 40px;
	display: inline-block;
	vertical-align: middle;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
}
.users_list_wrapper .emp-card.white:hover img {
	border: 1px solid #0d527b;
}
.users_list_wrapper .emp-card.white .username,
.users_list_wrapper .emp-card.white .empname {
	display: inline-block;
	vertical-align: middle;
	padding: 0 15px;
	font-family: 'bpgc';
	font-size: 12px;
	color: #658d8e;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
}
.users_list_wrapper .emp-card.white .empname {
	display: block;
	padding: 5px 0 0 ;
	font-size: 9px;
}
.users_list_wrapper .emp-card.white:hover .username {
	color: #0d527b;
}

.alert-danger {
	-webkit-border-radius: 40px;
	-moz-border-radius: 40px;
	border-radius: 40px;
}
.alert-danger ul {
	list-style: none;
	font-size: 12px;
}

/* popup */
.cdk-global-overlay-wrapper {
	justify-content: end !important;
}
.cdk-global-overlay-wrapper .cdk-overlay-pane {
	width: 300px !important;
	max-width: 300px !important;
	height: 100vh !important;
	margin: 0 -450px 0 0 !important;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
}
@media screen and (max-width: 760px) {
	.cdk-global-overlay-wrapper .cdk-overlay-pane {
		width: 300px !important;
		max-width: 300px !important;
	}
}
.cdk-overlay-backdrop-showing + .cdk-global-overlay-wrapper .cdk-overlay-pane {
	margin: 0 !important;
}
.cdk-overlay-pane .mat-dialog-container {
	padding: 30px !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
	-webkit-transform: scale(1) !important;
	-moz-transform: scale(1) !important;
	-o-transform: scale(1) !important;
	-ms-transform: scale(1) !important;
	transform: scale(1) !important;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
}
.cdk-overlay-pane .mat-dialog-container .mat-card {
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
	background: none !important;
	padding: 0 !important;
	font-family: 'bpgc';
	text-transform: uppercase;
}
.cdk-overlay-pane .mat-dialog-container .mat-card .date {
	color: #999;
	margin-bottom: 5px;
	font-size: 12px;
}
.cdk-overlay-pane .mat-dialog-container .mat-card img {
	width: 100%;
	height: auto;
}
.cdk-overlay-pane .mat-dialog-container .mat-card .empname {
	font-size: 16px;
	color: #0d527b;
	padding: 20px 0;
}
.cdk-overlay-pane .mat-dialog-container .mat-card hr {
	display: none;
}
.cdk-overlay-pane .mat-dialog-container .mat-card .detail {
	color: #777;
	font-size: 12px;
}



.top_search .mat-input-element {
	font-family: 'bpg';
} 
.top_search .mat-form-field-label-wrapper {
	top: 0;
	padding-top: 0
}
.top_search .mat-form-field-appearance-legacy .mat-form-field-wrapper {
	padding-bottom: 0
}
.top_search .mat-form-field-appearance-legacy .mat-form-field-infix {
	padding: 0;
	width: 300px !important;
	border: none !important;
}
.top_search .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 50%;
  margin-top: -7.5px;
  color: #FFF;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  opacity: .5;
  font-family: 'bpgc';
  text-transform: uppercase;
  padding-left: 30px;
  font-size: 12px;
}
.top_search .mat-form-field.mat-focused .mat-form-field-label {
  color: #FFF
}
.top_search .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
.top_search .mat-input-element {
  caret-color: #FFF
}



.home_tabs .mat-tab-label-content img {
	width: 20px;
	margin-right: 16px;
}
.home_tabs .mat-tab-label-content span {
	font-family: 'bpgc';
	color: #555;
	font-size: 12px;
}
.home_tabs .mat-tab-label-active .mat-tab-label-content span {
	color: #0d527b;
}
.home_tabs .mat-tab-label {
	height: 60px !important;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
	filter: alpha(opacity=100) !important;
	opacity: 1 !important;
}
.home_tabs .mat-ink-bar {
	background-color: #0d527b !important;
	margin-bottom: -1px;
}
.home_tabs .mat-card {
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
	background: none !important;
}
.home_tabs .mat-card {
	width: 100%;
	max-width: 160px;
	display: inline-block !important;
	cursor: pointer;
	padding: 10px !important;
	text-align: center;
	position: relative;
}
.home_tabs .shortdate,
.home_tabs .month {
	display: none;
}
.home_tabs .photo {
	width: 90px;
	height: 90px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: 1px solid #dfdfdf;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
}
.home_tabs .mat-card-content {
	position: relative;
}
.home_tabs .mat-card-content::after {
	width: 90px;
	height: 90px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background: rgba(0, 0, 0, .5);
	content: '';
	position: absolute;
	left: 50%;
	top: 26px;
	z-index: 10;
	margin: 0 0 0 -45px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	pointer-events: none;
}
.home_tabs .mat-card:hover .mat-card-content::after {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
}
.home_tabs .mat-card-content::before {
	width: 90px;
	height: 90px;
	background: url(assets/img/arrow.png) no-repeat center center;
	content: '';
	position: absolute;
	left: 50%;
	top: 26px;
	z-index: 20;
	margin: 0 0 0 -45px;
	-webkit-transform: scale(0.5);
	-moz-transform: scale(0.5);
	-o-transform: scale(0.5);
	-ms-transform: scale(0.5);
	transform: scale(0.5);
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	pointer-events: none;
}
.home_tabs .mat-card:hover .mat-card-content::before {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}
.home_tabs .mat-card:hover .photo {
	border: 1px solid #0d527b;
}
.home_tabs .mat-card .username {
	color: #444;
	font-family: 'bpgc';
	font-size: 12px;
	margin: 10px 0 0 0;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
}
.home_tabs .mat-card:hover .username {
	color: #0d527b;
}
.home_tabs .mat-card .empname {
	color: #777;
	font-family: 'bpgc';
	font-size: 9px;
	padding: 12px 0 0 0;
}


.mat-table {
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
	background: none;
	margin-bottom: 0;
	font-family: 'bpgc';
	font-size: 12px;
	border: none;
}
.mat-table tr.mat-header-row {
	height: auto !important;
}
.mat-table thead tr th {
	padding: 20px !important;
	border-bottom: none !important;
	color: #6fa3a5;
}
.mat-table thead tr {
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
	border-bottom: 1px solid #b5d7d8;
}
.mat-table tbody tr td {
	border-bottom: 1px solid #b5d7d8;
	color: #0d527b;
	font-family: 'bpgc';
	font-size: 12px;
	padding: 10px 20px;
}
.mat-table tbody tr:last-child td {
	border-bottom: none !important;
}
.mat-table tbody tr td:first-child {
	color: #6fa3a5;
}
.mat-table tbody tr {
	height: auto !important;
}
.table_div {
	width: auto;
	height: auto;
	-webkit-border-radius: 14px !important;
	-moz-border-radius: 14px !important;
	border-radius: 14px !important;
	border: 1px solid #b5d7d8;
	overflow: hidden;
	display: inline-block;
	margin-bottom: 30px;
}
@media screen and (max-width: 760px) {
	.table_div {
		width: 100%;
		overflow-x: auto;
	}
}


.forms_title {
    width: 100%;
    height: auto;
    border-bottom: 2px solid #b5d7d8;
    color: #0d527b;
    font-size: 12px;
    padding: 20px;
    margin-bottom: 30px;
}
.forms_title svg {
	width: auto;
	height: 20px;
	fill: #0d527b;
	vertical-align: middle;
	margin-right: 10px;
}


.new_holiday_form {
	width: auto;
	min-width: 50%;
	height: auto;
	-webkit-border-radius: 14px !important;
	-moz-border-radius: 14px !important;
	border-radius: 14px !important;
	border: 1px solid #b5d7d8;
	overflow: hidden;
	display: inline-block;
	margin-bottom: 30px;
	font-size: 13px;
	color: #777;
}
.new_holiday_form .form_user {
	padding: 20px;
	background: #d8f9fa;
	border-bottom: 1px solid #b5d7d8;
	color: #777;
}
.new_holiday_form .form_user span {
	color: #0d527b;
	padding-left: 10px;
}
.form_row {
	width: 100%;
	height: auto;
	padding: 0 20px;
	border-bottom: 1px solid #b9e1e2;
	line-height: 38px;
}
@media screen and (max-width: 760px) {
	.form_row {
		padding: 0 10px;
	}
}
.form_row_2 {
	width: 100%;
	height: auto;
	padding: 20px;
	border-bottom: 1px solid #b9e1e2;
}
.form_row_3 {
	width: 100%;
	height: auto;
	padding: 20px;
}
.form_row_3 button {
	margin: 20px 0 0 0;
	background: #0d527b !important;
	border: none;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
	filter: alpha(opacity=100) !important;
	opacity: 1 !important;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	font-size: 12px;
	padding: 10px 35px !important;
	cursor: pointer;
}
.ng-valid .form_row_3 button:hover {
	background: #14699b !important;
}
.ng-invalid .form_row_3 button {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)" !important;
	filter: alpha(opacity=50) !important;
	opacity: .5 !important;
	pointer-events:none;
}
.form_row_2 span {
	border: 1px solid #b9e1e2 !important;
	background: #d4f7f8 !important;
	color: #0d527b;
	padding: 7px 15px;
	margin: 0 5px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}
.new_holiday_form .row .col-sm-6 {
	padding-top: 15px;
	padding-bottom: 15px;
}
.new_holiday_form .row .col-sm-6:first-child {
	border-right: 1px solid #b5d7d8;
}
.new_holiday_form .row .col-sm-6:last-child {
	padding-left: 20px;
}
@media screen and (max-width: 760px) {
	.new_holiday_form .row .col-sm-6 {
		padding-top: 5px;
		padding-bottom: 5px;
	}
	.new_holiday_form .row .col-sm-6:last-child {
		padding-left: 10px;
	}
	.new_holiday_form .row .col-sm-6:first-child {
		border-right: none;
	}
}
.new_holiday_form label {
	line-height: 38px;
}
.new_holiday_form select {
	border: 1px solid #b9e1e2 !important;
	background: #d4f7f8 !important;
	color: #0d527b;
	border-radius: 20px;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 13px;
	font-family: 'bpg';
}
.form_span_color span {
	padding-left: 10px;
	color: #0d527b;
}
.new_holiday_form input[type=text],
.new_holiday_form input[type=date],
.new_holiday_form textarea {
	border: 1px solid #b9e1e2;
	background: #d4f7f8;
	color: #0d527b;
	border-radius: 20px;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 13px;
	font-family: 'bpg';
}
.new_holiday_form input[type=text]:focus,
.new_holiday_form input[type=date]:focus {
	border: 1px solid #87b5b6;
}
.new_holiday_form textarea:focus {
	border: 1px solid #87b5b6 !important;
	background: #d4f7f8 !important;
}

.new_holiday_form .mat-input-element {
	width: -moz-calc(100% - 10px) !important;
	width: -webkit-calc(100% - 10px) !important;
	width: calc(100% - 10px) !important;
	height: 38px;
	border: 1px solid #b9e1e2 !important;
	background: #d4f7f8 !important;
	color: #0d527b !important;
	border-radius: 20px;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 13px;
	font-family: 'bpg';
	padding-left: 10px !important;
}
.new_holiday_form .mat-datepicker-toggle {
	width: 38px;
	height: 38px;
	position: absolute;
	right: 13px;
	top: 14px;
}
.new_holiday_form .mat-datepicker-toggle .mat-ripple,
.new_holiday_form .mat-datepicker-toggle .mat-button-focus-overlay {
	display: none !important;
}
.new_holiday_form  .mat-datepicker-toggle svg {
	fill: #b9e1e2 !important;
}
.mat-datepicker-popup .mat-datepicker-content {
	background: #d4f7f8 !important;
	-webkit-border-radius: 14px !important;
	-moz-border-radius: 14px !important;
	border-radius: 14px !important;
	border: 1px solid #b9e1e2 !important;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
	color: #0d527b !important;
}
.mat-datepicker-popup table tbody tr td .mat-calendar-body-cell-content {
	color: #0d527b !important;
	background: #d4f7f8 !important;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
}
.mat-datepicker-popup table tbody tr td .mat-calendar-body-cell-content:visited,
.mat-datepicker-popup table tbody tr td .mat-calendar-body-cell-content:hover {
	background: #bee7e9 !important;
}
.mat-datepicker-popup table tbody tr td .mat-calendar-body-selected {
	color: #FFF !important;
	background: #0d527b !important;
}
.mat-datepicker-popup table tbody tr td .mat-calendar-body-today {
	border: 1px solid #0d527b !important;
	background: #bee7e9 !important;
}


.m_content_m_height {
	width: 100%;
	height: auto;
	min-height: 65vh;
}
@media screen and (max-width: 1400px) {
	.m_content_m_height {
		min-height: 60vh;
	}
}
@media screen and (max-width: 760px) {
	.m_content_m_height {
		min-height: 72vh;
	}
}
.mat-nav-list .mat-list-base .mat-list-item {
  color: #FFF !important;
}
.mat-nav-list .mat-list-base .mat-list-item::before {
	width: 1px;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	content: '';
	background: #FFF;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
}
.mat-nav-list .mat-list-base .mat-list-item.active::before {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
	left: 3px;
}
.mat-nav-list .mat-list-base .mat-list-item.active {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
	filter: alpha(opacity=100) !important;
	opacity: 1 !important;
}
.mat-table .mat-fab,
.approve_form .mat-fab {
	width: auto !important;
	height: 34px !important;
	-webkit-border-radius: 40px !important;
	-moz-border-radius: 40px !important;
	border-radius: 40px !important;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
}
.mat-table .mat-fab.btn_red,
.approve_form .mat-fab.btn_red {
	background: #b13535;
}
.mat-table .mat-fab.btn_red:hover,
.approve_form .mat-fab.btn_red:hover {
	background: #8f2525;
}
.approve_form .mat-fab.btn_green {
	background: #5ccc3a;
}
.approve_form .mat-fab.btn_green:hover {
	background: #4fb92f;
}
.mat-table .mat-fab.btn_blue {
	background: #0d527b;
}
.form_row .mat-fab {
	margin: 15px 15px 15px 0 !important;
}
.mat-table .mat-fab.btn_blue svg {
	width: auto;
	height: 12px;
	margin: -4px 0 0 5px;
	vertical-align: middle;
}
.mat-table .mat-fab.btn_blue:hover {
	background: #073550;
}
.mat-table .mat-fab span,
.approve_form .mat-fab span {
	padding: 0 25px !important;
}
.mat-table .mat-fab .mat-icon,
.approve_form .mat-fab .mat-icon {
	width: auto !important;
	height: 34px !important;
	line-height: 32px !important;
	padding: 0 !important;
	font-family: 'bpgc';
	font-size: 12px;
	color: #FFF;
}

.user_pic img {
	width: 100%;
	height: auto;
}

.top_company_logo img {
	width: auto;
	height: auto;
	max-height: 80%;
}


.mat-form-field-appearance-outline .mat-form-field-outline-end {
	border: 1px solid #b9e1e2 !important;
	background: #d4f7f8 !important;
	color: #0d527b !important;
	border-radius: 30px !important;
	font-family: 'bpg' !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-start {
	display: none !important;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)" !important;
	filter: alpha(opacity=0) !important;
	opacity: 0 !important;
}
.mat-select-value {
	min-width: 250px;
}
.mat-form-field-infix {
	min-width: 270px !important;
}
.mat-form-field-wrapper {
	margin: 15px 0 0 0 !important;
	padding-bottom: 0 !important;
}
.mat-select-value-text {
	font-size: 13px;
	font-family: 'bpgc';
	color: #0d527b !important;
	padding: 0 0 0 10px !important;
}
.mat-select-panel {
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
	background: #d4f7f8 !important;
	border: 1px solid #b9e1e2 !important;
	-webkit-border-radius: 8px !important;
	-moz-border-radius: 8px !important;
	border-radius: 8px !important;
}
.mat-select-panel .mat-option {
	color: #555 !important;
	font-size: 13px !important;
	font-family: 'bpg' !important;
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
	background: #0d527b !important;
	color: #FFF !important;
}
.mat-select-arrow {
	color: #0d527b !important;
}
.mat-form-field-infix {
	border-top: 4px solid transparent !important;
}
.mat-select-arrow-wrapper {
	padding: 7px 0 0 0 !important;
}
.tabel_users_container {
	width: 360px;
	height: auto;
	display: inline-block;
	vertical-align: top;
}
.select_users {
	width: 100%;
	height: auto;
	min-height: 400px;
	border: 1px solid #0d527b !important;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	overflow: hidden;
	margin: 0;
	background: #d4f7f8 !important;
}
@media screen and (max-width: 760px) {
	.tabel_users_container {
		display: none;
	}
}
.select_users .radio_select {
	width: 100%;
	height: auto;
	position: relative;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
}
.select_users .radio_select p {
	padding: 7px 12px;
	margin: 0;
	color: #597e80;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out; 
}
.select_users .radio_select:hover {
	background: #b7e6e7;
}
.select_users .radio_select:hover p {
	color: #4f6d6e;
}
.select_users .radio_select input {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
	cursor: pointer;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
}
.select_users .radio_select input:checked + p {
	color: #FFF;
	background: #0d527b;
}
.tabel_container {
	width: -moz-calc(100% - 400px);
	width: -webkit-calc(100% - 400px);
	width: calc(100% - 400px);
	height: auto;
	display: inline-block;
	vertical-align: top;
	margin: 0 0 0 30px;
}
@media screen and (max-width: 760px) {
	.tabel_container {
		width: 100%;
		margin: 0;
	}
}
.timesheet_list_wrapper {
	width: auto;
	min-width: 70%;
	height: auto;
	border: 1px solid #0d527b !important;
	display: inline-block;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	overflow: hidden;
	margin: 0;
}
@media screen and (max-width: 1500px) {
	.timesheet_list_wrapper {
		min-width: 100%;
	}
}
.timesheet_list_wrapper h4 {
	background: #0d527b !important;
	font-size: 14px !important;
	padding: 10px;
	border-bottom: none !important;
	color: #FFF !important;
	margin: 0 !important;
}
.timesheet_list_wrapper .mat-table thead tr th {
	padding: 10px !important;
	background: #d4f7f8 !important;
}
.timesheet_list_wrapper h4 span {
	color: #0d527b !important;
}
.timesheet_list_wrapper table {
	width: 100% !important;
}
.timesheet_list_wrapper tfoot {
	background: #d4f7f8 !important;
	border-top: 1px solid #b9e1e2 !important;
	border-bottom: none !important;
	padding: 15px 0 0 0 !important;
}
.tabel_select_mobile {
	width: 100%;
	height: auto;
	display: none;
}
@media screen and (max-width: 760px) {
	.tabel_select_mobile {
		display: block;
	}
}
.tabel_select_mobile .tabel_title {
	padding-bottom: 0;
}
.tabel_title {
	width: 100%;
	height: auto;
	margin: 0;
	padding: 10px 10px 10px 20px;
	color: #0d527b !important;
	font-size: 12px;
}
.tabel_title img {
	width: auto;
	height: 18px;
	margin: 0 5px 3px 0;
	vertical-align: middle;
}
.timesheet_list_wrapper tbody tr td {
	color: #0d527b !important;
	padding: 10px !important;
}
.timesheet_list_wrapper tfoot td, .timesheet_list_wrapper td.mat-footer-cell {
	padding: 10px !important;
	color: #6fa3a5 !important;
	border-bottom: none !important;
}
.timesheet_list_wrapper tfoot span {
	color: #0d527b !important;
}
.empty_timesheet {
	line-height: 370px;
	color: #78abac;
	padding: 15px;
	text-align: center;
}
@media screen and (max-width: 760px) {
	.timesheet_list_wrapper thead tr th,
	.timesheet_list_wrapper tfoot td, .timesheet_list_wrapper td.mat-footer-cell,
	.timesheet_list_wrapper tbody tr td {
		padding: 6px 10px !important;
	}
	.timesheet_list_wrapper {
		min-width: 100%;
	}
	.timesheet_list_wrapper table {
		width: 100%;
		height: auto;
		overflow-x: auto;
	}
}